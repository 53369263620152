import { ChainId, CHAINKEY, Token } from '@my/sdk';
import { BASE_BSC_SCAN_URL, chainKey } from 'config';
// change chain
export const chainId = ChainId.ASTR_MAINNET;

const tokens = {
  [CHAINKEY.ASTR]: {
    ibASTR: {
      symbol: 'ibASTR',
      address: {
        [ChainId.ASTR_MAINNET]: '0x3BFcAE71e7d5ebC1e18313CeCEbCaD8239aA386c',
        [ChainId.ASTR_TESTNET]: '0x50CE77Ed745374980aE8366424e79D08bD1BB37B',
      },
      decimals: 18,
      projectLink: '',
    },
    wastr: {
      symbol: 'WASTR',
      address: {
        [ChainId.ASTR_MAINNET]: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
        [ChainId.ASTR_TESTNET]: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
      },
      decimals: 18,
      projectLink: 'https://blockscout.com/astar/',
    },
    kaco: {
      symbol: 'KAC',
      address: {
        [ChainId.ASTR_MAINNET]: '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
        [ChainId.ASTR_TESTNET]: '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
      },
      decimals: 18,
      projectLink: 'https://kaco.finance/',
    },

    usdt: {
      symbol: 'USDT',
      address: {
        [ChainId.ASTR_MAINNET]: '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
        [ChainId.ASTR_TESTNET]: '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
      },
      decimals: 18,
      projectLink: 'https://tether.to/',
    },

    usdc: {
      symbol: 'USDC',
      address: {
        [ChainId.ASTR_MAINNET]: '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
        [ChainId.ASTR_TESTNET]: '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
      },
      decimals: 6,
      projectLink: 'https://www.centre.io/usdc',
    },

    dai: {
      symbol: 'DAI',
      address: {
        [ChainId.ASTR_MAINNET]: '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
        [ChainId.ASTR_TESTNET]: '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
      },
      decimals: 18,
      projectLink: 'https://www.centre.io/usdc',
    },

    weth: {
      symbol: 'WETH: ',
      address: {
        [ChainId.ASTR_MAINNET]: '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
        [ChainId.ASTR_TESTNET]: '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
      },
      decimals: 18,
      projectLink: 'https://ethereum.org/en/',
    },

    bnb: {
      symbol: 'BNB',
      address: {
        [ChainId.ASTR_MAINNET]: '0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52',
        [ChainId.ASTR_TESTNET]: '0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52',
      },
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },
    busd: {
      symbol: 'BUSD',
      address: {
        [ChainId.ASTR_MAINNET]: '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E',
        [ChainId.ASTR_TESTNET]: '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E',
      },
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },

    wbtc: {
      symbol: 'WBTC',
      address: {
        [ChainId.ASTR_MAINNET]: '0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA',
        [ChainId.ASTR_TESTNET]: '0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA',
      },
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },

    pkex: {
      symbol: 'PKEX',
      address: {
        [ChainId.ASTR_MAINNET]: '0x1fE622E91e54D6AD00B01917351Ea6081426764A',
        [ChainId.ASTR_TESTNET]: '0x1fE622E91e54D6AD00B01917351Ea6081426764A',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    sdn: {
      symbol: 'SDN',
      address: {
        [ChainId.ASTR_MAINNET]: '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4',
        [ChainId.ASTR_TESTNET]: '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    matic: {
      symbol: 'MATIC',
      address: {
        [ChainId.ASTR_MAINNET]: '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
        [ChainId.ASTR_TESTNET]: '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    aave: {
      symbol: 'AAVE',
      address: {
        [ChainId.ASTR_MAINNET]: '0xfcDe4A87b8b6FA58326BB462882f1778158B02F1',
        [ChainId.ASTR_TESTNET]: '0xfcDe4A87b8b6FA58326BB462882f1778158B02F1',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },

    crv: {
      symbol: 'CRV',
      address: {
        [ChainId.ASTR_MAINNET]: '0x7756a83563f0f56937A6FdF668E7D9F387c0D199',
        [ChainId.ASTR_TESTNET]: '0x7756a83563f0f56937A6FdF668E7D9F387c0D199',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },
  },
  [CHAINKEY.SDN]: {
    syrup: {
      symbol: 'SYRUP',
      address: {
        [ChainId.SDN_MAINNET]: '0x808764026aDddb9E7dFAAEA846977cCe6425D593',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://www.kaco.finance/',
    },
    kaco: {
      symbol: 'KAC',
      address: {
        [ChainId.SDN_MAINNET]: '0xb12c13e66ade1f72f71834f2fc5082db8c091358',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://kaco.finance/',
    },
    usdt: {
      symbol: 'USDT',
      address: {
        [ChainId.SDN_MAINNET]: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://tether.to/',
    },
    usdc: {
      symbol: 'USDC',
      address: {
        [ChainId.SDN_MAINNET]: '0xfa9343c3897324496a05fc75abed6bac29f8a40f',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 6,
      projectLink: 'https://www.centre.io/usdc',
    },
    dai: {
      symbol: 'DAI',
      address: {
        [ChainId.SDN_MAINNET]: '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://www.makerdao.com/',
    },
    eth: {
      symbol: 'ETH',
      address: {
        [ChainId.SDN_MAINNET]: '0x765277eebeca2e31912c9946eae1021199b39c61',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://ethereum.org/en/',
    },
    wbtc: {
      symbol: 'WBTC',
      address: {
        [ChainId.SDN_MAINNET]: '0x922d641a426dcffaef11680e5358f34d97d112e1',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },
    busd: {
      symbol: 'BUSD',
      address: {
        [ChainId.SDN_MAINNET]: '0x65e66a61d0a8f1e686c2d6083ad611a10d84d97a',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },
    jpyc: {
      symbol: 'JPYC',
      address: {
        [ChainId.SDN_MAINNET]: '0x735abe48e8782948a37c7765ecb76b98cde97b0f',
        [ChainId.SDN_TESTNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      },
      decimals: 18,
      projectLink: 'https://jpyc.jp/',
    },
  },
  [CHAINKEY.BSC]: {
    bnb: {
      symbol: 'BNB',
      projectLink: 'https://www.binance.com/',
    },
    kaco: {
      symbol: 'KAC',
      address: {
        [ChainId.BSC_MAINNET]: '0xf96429A7aE52dA7d07E60BE95A3ece8B042016fB',
        [ChainId.BSC_TESTNET]: '0x0bA819e30016Cf682C7795b44859148C65e62292',
      },
      decimals: 18,
      projectLink: 'https://kaco.finance/',
    },
    clv: {
      symbol: 'CLV',
      address: {
        [ChainId.BSC_MAINNET]: '0x09e889bb4d5b474f561db0491c38702f367a4e4d',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: '',
    },
    ksm: {
      symbol: 'KSM',
      address: {
        [ChainId.BSC_MAINNET]: '0x2aa69e8d25c045b659787bc1f03ce47a388db6e8',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: '',
    },
    babycake: {
      symbol: 'BABYCAKE',
      address: {
        [ChainId.BSC_MAINNET]: '0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://babycake.app/',
    },
    bmon: {
      symbol: 'BMON',
      address: {
        [ChainId.BSC_MAINNET]: '0x08ba0619b1e7A582E0BCe5BBE9843322C954C340',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://binamon.org/',
    },
    hero: {
      symbol: 'HERO',
      address: {
        [ChainId.BSC_MAINNET]: '0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://metahero.io/',
    },
    wsg: {
      symbol: 'WSG',
      address: {
        [ChainId.BSC_MAINNET]: '0xA58950F05FeA2277d2608748412bf9F802eA4901',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://wsg.gg/',
    },
    mcrn: {
      symbol: 'MCRN',
      address: {
        [ChainId.BSC_MAINNET]: '0xacb2d47827C9813AE26De80965845D80935afd0B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.macaronswap.finance/',
    },
    revv: {
      symbol: 'REVV',
      address: {
        [ChainId.BSC_MAINNET]: '0x833f307ac507d47309fd8cdd1f835bef8d702a93',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://revvmotorsport.com/',
    },
    skill: {
      symbol: 'SKILL',
      address: {
        [ChainId.BSC_MAINNET]: '0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.cryptoblades.io/',
    },
    if: {
      symbol: 'IF',
      address: {
        [ChainId.BSC_MAINNET]: '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://impossible.finance/',
    },
    sps: {
      symbol: 'SPS',
      address: {
        [ChainId.BSC_MAINNET]: '0x1633b7157e7638C4d6593436111Bf125Ee74703F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://splinterlands.com',
    },
    chess: {
      symbol: 'CHESS',
      address: {
        [ChainId.BSC_MAINNET]: '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://tranchess.com/',
    },
    titan: {
      symbol: 'TITAN',
      address: {
        [ChainId.BSC_MAINNET]: '0xe898EDc43920F357A93083F1d4460437dE6dAeC2',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://titanswap.org',
    },
    harmony: {
      symbol: 'ONE',
      address: {
        [ChainId.BSC_MAINNET]: '0x03fF0ff224f904be3118461335064bB48Df47938',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.harmony.one/',
    },
    mask: {
      symbol: 'MASK',
      address: {
        [ChainId.BSC_MAINNET]: '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mask.io/',
    },
    dvi: {
      symbol: 'DVI',
      address: {
        [ChainId.BSC_MAINNET]: '0x758FB037A375F17c7e195CC634D77dA4F554255B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://dvision.network/',
    },
    adx: {
      symbol: 'ADX',
      address: {
        [ChainId.BSC_MAINNET]: '0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.adex.network',
    },
    bscpad: {
      symbol: 'BSCPAD',
      address: {
        [ChainId.BSC_MAINNET]: '0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bscpad.com/',
    },
    rabbit: {
      symbol: 'RABBIT',
      address: {
        [ChainId.BSC_MAINNET]: '0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://rabbitfinance.io/earn',
    },
    form: {
      symbol: 'FORM',
      address: {
        [ChainId.BSC_MAINNET]: '0x25A528af62e56512A19ce8c3cAB427807c28CC19',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://formation.fi/',
    },
    txl: {
      symbol: 'TXL',
      address: {
        [ChainId.BSC_MAINNET]: '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://tixl.org/',
    },
    orbs: {
      symbol: 'ORBS',
      address: {
        [ChainId.BSC_MAINNET]: '0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.orbs.com/',
    },
    cos: {
      symbol: 'COS',
      address: {
        [ChainId.BSC_MAINNET]: '0x96Dd399F9c3AFda1F194182F71600F1B65946501',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.contentos.io/',
    },
    bunny: {
      symbol: 'BUNNY',
      address: {
        [ChainId.BSC_MAINNET]: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://pancakebunny.finance/',
    },
    alice: {
      symbol: 'ALICE',
      address: {
        [ChainId.BSC_MAINNET]: '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 6,
      projectLink: 'https://www.myneighboralice.com/',
    },
    for: {
      symbol: 'FOR',
      address: {
        [ChainId.BSC_MAINNET]: '0x658A109C5900BC6d2357c87549B651670E5b0539',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.for.tube/home',
    },
    bux: {
      symbol: 'BUX',
      address: {
        [ChainId.BSC_MAINNET]: '0x211FfbE424b90e25a15531ca322adF1559779E45',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://getbux.com/bux-crypto/',
    },
    nuls: {
      symbol: 'NULS',
      address: {
        [ChainId.BSC_MAINNET]: '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://www.nuls.io/',
    },
    belt: {
      symbol: 'BELT',
      address: {
        [ChainId.BSC_MAINNET]: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://beta.belt.fi/',
    },
    ramp: {
      symbol: 'RAMP',
      address: {
        [ChainId.BSC_MAINNET]: '0x8519EA49c997f50cefFa444d240fB655e89248Aa',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://rampdefi.com/',
    },
    bfi: {
      symbol: 'BFI',
      address: {
        [ChainId.BSC_MAINNET]: '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bearn.fi/',
    },
    dexe: {
      symbol: 'DEXE',
      address: {
        [ChainId.BSC_MAINNET]: '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://dexe.network/',
    },
    bel: {
      symbol: 'BEL',
      address: {
        [ChainId.BSC_MAINNET]: '0x8443f091997f06a61670B735ED92734F5628692F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bella.fi/',
    },
    tpt: {
      symbol: 'TPT',
      address: {
        [ChainId.BSC_MAINNET]: '0xECa41281c24451168a37211F0bc2b8645AF45092',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 4,
      projectLink: 'https://www.tokenpocket.pro/',
    },
    watch: {
      symbol: 'WATCH',
      address: {
        [ChainId.BSC_MAINNET]: '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://yieldwatch.net/',
    },
    xmark: {
      symbol: 'xMARK',
      address: {
        [ChainId.BSC_MAINNET]: '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 9,
      projectLink: 'https://benchmarkprotocol.finance/',
    },
    bmxx: {
      symbol: 'bMXX',
      address: {
        [ChainId.BSC_MAINNET]: '0x4131b87F74415190425ccD873048C708F8005823',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://multiplier.finance/',
    },
    iotx: {
      symbol: 'IOTX',
      address: {
        [ChainId.BSC_MAINNET]: '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://iotex.io/',
    },
    bor: {
      symbol: 'BOR',
      address: {
        [ChainId.BSC_MAINNET]: '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.boringdao.com/',
    },
    bopen: {
      symbol: 'bOPEN',
      address: {
        [ChainId.BSC_MAINNET]: '0xF35262a9d427F96d2437379eF090db986eaE5d42',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://opendao.io/',
    },
    dodo: {
      symbol: 'DODO',
      address: {
        [ChainId.BSC_MAINNET]: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://dodoex.io/',
    },
    swingby: {
      symbol: 'SWINGBY',
      address: {
        [ChainId.BSC_MAINNET]: '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://swingby.network/',
    },
    bry: {
      symbol: 'BRY',
      address: {
        [ChainId.BSC_MAINNET]: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://berrydata.co/',
    },
    zee: {
      symbol: 'ZEE',
      address: {
        [ChainId.BSC_MAINNET]: '0x44754455564474A89358B2C2265883DF993b12F0',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://zeroswap.io/',
    },
    swgb: {
      symbol: 'SWGb',
      address: {
        [ChainId.BSC_MAINNET]: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://swirgepay.com/',
    },
    swg: {
      symbol: 'SWG',
      address: {
        [ChainId.BSC_MAINNET]: '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://swirgepay.com/',
    },
    sfp: {
      symbol: 'SFP',
      address: {
        [ChainId.BSC_MAINNET]: '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.safepal.io/',
    },
    lina: {
      symbol: 'LINA',
      address: {
        [ChainId.BSC_MAINNET]: '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://linear.finance/',
    },
    lit: {
      symbol: 'LIT',
      address: {
        [ChainId.BSC_MAINNET]: '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.litentry.com/',
    },
    hget: {
      symbol: 'HGET',
      address: {
        [ChainId.BSC_MAINNET]: '0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 6,
      projectLink: 'https://www.hedget.com/',
    },
    bdo: {
      symbol: 'BDO',
      address: {
        [ChainId.BSC_MAINNET]: '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bdollar.fi/',
    },
    egld: {
      symbol: 'EGLD',
      address: {
        [ChainId.BSC_MAINNET]: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://elrond.com/',
    },
    ust: {
      symbol: 'UST',
      address: {
        [ChainId.BSC_MAINNET]: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    wsote: {
      symbol: 'wSOTE',
      address: {
        [ChainId.BSC_MAINNET]: '0x541E619858737031A1244A5d0Cd47E5ef480342c',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://soteria.finance/#/',
    },
    front: {
      symbol: 'FRONT',
      address: {
        [ChainId.BSC_MAINNET]: '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://frontier.xyz/',
    },
    helmet: {
      symbol: 'Helmet',
      address: {
        [ChainId.BSC_MAINNET]: '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.helmet.insure/',
    },
    btcst: {
      symbol: 'BTCST',
      address: {
        [ChainId.BSC_MAINNET]: '0x78650B139471520656b9E7aA7A5e9276814a38e9',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 17,
      projectLink: 'https://www.1-b.tc/',
    },
    bscx: {
      symbol: 'BSCX',
      address: {
        [ChainId.BSC_MAINNET]: '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bscex.org/',
    },
    ten: {
      symbol: 'TEN',
      address: {
        [ChainId.BSC_MAINNET]: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.tenet.farm/',
    },
    balbt: {
      symbol: 'bALBT',
      address: {
        [ChainId.BSC_MAINNET]: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://allianceblock.io/',
    },
    asr: {
      symbol: 'ASR',
      address: {
        [ChainId.BSC_MAINNET]: '0x80D5f92C2c8C682070C95495313dDB680B267320',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    atm: {
      symbol: 'ATM',
      address: {
        [ChainId.BSC_MAINNET]: '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    og: {
      symbol: 'OG',
      address: {
        [ChainId.BSC_MAINNET]: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    reef: {
      symbol: 'REEF',
      address: {
        [ChainId.BSC_MAINNET]: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://reef.finance/',
    },
    ditto: {
      symbol: 'DITTO',
      address: {
        [ChainId.BSC_MAINNET]: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 9,
      projectLink: 'https://ditto.money/',
    },
    juv: {
      symbol: 'JUV',
      address: {
        [ChainId.BSC_MAINNET]: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    psg: {
      symbol: 'PSG',
      address: {
        [ChainId.BSC_MAINNET]: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 2,
      projectLink: 'https://www.chiliz.com',
    },
    vai: {
      symbol: 'VAI',
      address: {
        [ChainId.BSC_MAINNET]: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://venus.io/',
    },
    wbnb: {
      symbol: 'wBNB',
      address: {
        [ChainId.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        [ChainId.BSC_TESTNET]: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    blink: {
      symbol: 'BLINK',
      address: {
        [ChainId.BSC_MAINNET]: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 6,
      projectLink: 'https://blink.wink.org',
    },
    unfi: {
      symbol: 'UNFI',
      address: {
        [ChainId.BSC_MAINNET]: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://unifiprotocol.com',
    },
    twt: {
      symbol: 'TWT',
      address: {
        [ChainId.BSC_MAINNET]: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://trustwallet.com/',
    },
    hard: {
      symbol: 'HARD',
      address: {
        [ChainId.BSC_MAINNET]: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 6,
      projectLink: 'https://hard.kava.io',
    },
    broobee: {
      symbol: 'bROOBEE',
      address: {
        [ChainId.BSC_MAINNET]: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://roobee.io/',
    },
    stax: {
      symbol: 'STAX',
      address: {
        [ChainId.BSC_MAINNET]: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://stablexswap.com/',
    },
    nar: {
      symbol: 'NAR',
      address: {
        [ChainId.BSC_MAINNET]: '0xA1303E6199b319a891b79685F0537D289af1FC83',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://narwhalswap.org/',
    },
    nya: {
      symbol: 'NYA',
      address: {
        [ChainId.BSC_MAINNET]: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://nyanswop.org/',
    },
    ctk: {
      symbol: 'CTK',
      address: {
        [ChainId.BSC_MAINNET]: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 6,
      projectLink: 'https://www.certik.foundation/',
    },
    inj: {
      symbol: 'INJ',
      address: {
        [ChainId.BSC_MAINNET]: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://injectiveprotocol.com/',
    },
    sxp: {
      symbol: 'SXP',
      address: {
        [ChainId.BSC_MAINNET]: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://swipe.io/',
    },
    alpha: {
      symbol: 'ALPHA',
      address: {
        [ChainId.BSC_MAINNET]: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://alphafinance.io/',
    },
    xvs: {
      symbol: 'XVS',
      address: {
        [ChainId.BSC_MAINNET]: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://venus.io/',
    },
    sushi: {
      symbol: 'SUSHI',
      address: {
        [ChainId.BSC_MAINNET]: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://sushi.com/',
    },
    comp: {
      symbol: 'COMP',
      address: {
        [ChainId.BSC_MAINNET]: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://compound.finance/',
    },
    syrup: {
      symbol: 'SYRUP',
      address: {
        [ChainId.BSC_MAINNET]: '0xd95D56A112D62DFc1e6F9bC2432d12b8e1B25d60',
        [ChainId.BSC_TESTNET]: '0x4b7349f116992EE73Cafe34d9Eaf3365f8192a9D',
      },
      decimals: 18,
      projectLink: 'https://kaco.finance/',
    },
    bifi: {
      symbol: 'BIFI',
      address: {
        [ChainId.BSC_MAINNET]: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://beefy.finance/',
    },
    dusk: {
      symbol: 'DUSK',
      address: {
        [ChainId.BSC_MAINNET]: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://dusk.network/',
    },
    busd: {
      symbol: 'BUSD',
      address: {
        [ChainId.BSC_MAINNET]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        [ChainId.BSC_TESTNET]: '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
      },
      decimals: 18,
      projectLink: 'https://www.paxos.com/busd/',
    },
    eth: {
      symbol: 'ETH',
      address: {
        [ChainId.BSC_MAINNET]: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://ethereum.org/en/',
    },
    beth: {
      symbol: 'BETH',
      address: {
        [ChainId.BSC_MAINNET]: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
    },
    mamzn: {
      symbol: 'mAMZN',
      address: {
        [ChainId.BSC_MAINNET]: '0x3947B992DC0147D2D89dF0392213781b04B25075',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    mgoogl: {
      symbol: 'mGOOGL',
      address: {
        [ChainId.BSC_MAINNET]: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    mnflx: {
      symbol: 'mNFLX',
      address: {
        [ChainId.BSC_MAINNET]: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    mtsla: {
      symbol: 'mTSLA',
      address: {
        [ChainId.BSC_MAINNET]: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    ltc: {
      symbol: 'LTC',
      address: {
        [ChainId.BSC_MAINNET]: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://litecoin.org/',
    },
    usdc: {
      symbol: 'USDC',
      address: {
        [ChainId.BSC_MAINNET]: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.centre.io/usdc',
    },
    dai: {
      symbol: 'DAI',
      address: {
        [ChainId.BSC_MAINNET]: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.makerdao.com/',
    },
    ada: {
      symbol: 'ADA',
      address: {
        [ChainId.BSC_MAINNET]: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://www.cardano.org/',
    },
    band: {
      symbol: 'BAND',
      address: {
        [ChainId.BSC_MAINNET]: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://bandprotocol.com/',
    },
    dot: {
      symbol: 'DOT',
      address: {
        [ChainId.BSC_MAINNET]: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://polkadot.network/',
    },
    eos: {
      symbol: 'EOS',
      address: {
        [ChainId.BSC_MAINNET]: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://eos.io/',
    },
    link: {
      symbol: 'LINK',
      address: {
        [ChainId.BSC_MAINNET]: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://chain.link/',
    },
    usdt: {
      symbol: 'USDT',
      address: {
        [ChainId.BSC_MAINNET]: '0x55d398326f99059fF775485246999027B3197955',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://tether.to/',
    },
    btcb: {
      symbol: 'BTCB',
      address: {
        [ChainId.BSC_MAINNET]: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://bitcoin.org/',
    },
    xrp: {
      symbol: 'XRP',
      address: {
        [ChainId.BSC_MAINNET]: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://ripple.com/xrp/',
    },
    atom: {
      symbol: 'ATOM',
      address: {
        [ChainId.BSC_MAINNET]: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://cosmos.network/',
    },
    yfii: {
      symbol: 'YFII',
      address: {
        [ChainId.BSC_MAINNET]: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://dfi.money/#/',
    },
    xtz: {
      symbol: 'XTZ',
      address: {
        [ChainId.BSC_MAINNET]: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://www.tezos.com/',
    },
    bch: {
      symbol: 'BCH',
      address: {
        [ChainId.BSC_MAINNET]: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://bch.info/',
    },
    yfi: {
      symbol: 'YFI',
      address: {
        [ChainId.BSC_MAINNET]: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://yearn.finance/',
    },
    uni: {
      symbol: 'UNI',
      address: {
        [ChainId.BSC_MAINNET]: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://uniswap.org/',
    },
    fil: {
      symbol: 'FIL',
      address: {
        [ChainId.BSC_MAINNET]: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://filecoin.io/',
    },
    bake: {
      symbol: 'BAKE',
      address: {
        [ChainId.BSC_MAINNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.bakeryswap.org/',
    },
    burger: {
      symbol: 'BURGER',
      address: {
        [ChainId.BSC_MAINNET]: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
        [ChainId.BSC_TESTNET]: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      },
      decimals: 18,
      projectLink: 'https://burgerswap.org/',
    },
    bdigg: {
      symbol: 'bDIGG',
      address: {
        [ChainId.BSC_MAINNET]: '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://badger.finance/',
    },
    bbadger: {
      symbol: 'bBadger',
      address: {
        [ChainId.BSC_MAINNET]: '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://badger.finance/',
    },
    trade: {
      symbol: 'TRADE',
      address: {
        [ChainId.BSC_MAINNET]: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://unitrade.app/',
    },
    pnt: {
      symbol: 'PNT',
      address: {
        [ChainId.BSC_MAINNET]: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://ptokens.io/',
    },
    mir: {
      symbol: 'MIR',
      address: {
        [ChainId.BSC_MAINNET]: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    pbtc: {
      symbol: 'pBTC',
      address: {
        [ChainId.BSC_MAINNET]: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://ptokens.io/',
    },
    lto: {
      symbol: 'LTO',
      address: {
        [ChainId.BSC_MAINNET]: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://ltonetwork.com/',
    },
    pcws: {
      symbol: 'pCWS',
      address: {
        [ChainId.BSC_MAINNET]: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://game.seascape.network/',
    },
    zil: {
      symbol: 'ZIL',
      address: {
        [ChainId.BSC_MAINNET]: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 12,
      projectLink: 'https://www.zilliqa.com/',
    },
    lien: {
      symbol: 'LIEN',
      address: {
        [ChainId.BSC_MAINNET]: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://lien.finance/',
    },
    swth: {
      symbol: 'SWTH',
      address: {
        [ChainId.BSC_MAINNET]: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://switcheo.network/',
    },
    dft: {
      symbol: 'DFT',
      address: {
        [ChainId.BSC_MAINNET]: '0x42712dF5009c20fee340B245b510c0395896cF6e',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.dfuture.com/home',
    },
    gum: {
      symbol: 'GUM',
      address: {
        [ChainId.BSC_MAINNET]: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://gourmetgalaxy.io/',
    },
    dego: {
      symbol: 'DEGO',
      address: {
        [ChainId.BSC_MAINNET]: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bsc.dego.finance/home',
    },
    nrv: {
      symbol: 'NRV',
      address: {
        [ChainId.BSC_MAINNET]: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://nerve.fi/',
    },
    easy: {
      symbol: 'EASY',
      address: {
        [ChainId.BSC_MAINNET]: '0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://easyfi.network/',
    },
    oddz: {
      symbol: 'ODDZ',
      address: {
        [ChainId.BSC_MAINNET]: '0xCD40F2670CF58720b694968698A5514e924F742d',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://oddz.fi/',
    },
    hoo: {
      symbol: 'HOO',
      address: {
        [ChainId.BSC_MAINNET]: '0xE1d1F66215998786110Ba0102ef558b22224C016',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://hoo.com/',
    },
    apys: {
      symbol: 'APYS',
      address: {
        [ChainId.BSC_MAINNET]: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://apyswap.com/',
    },
    bondly: {
      symbol: 'BONDLY',
      address: {
        [ChainId.BSC_MAINNET]: '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.bondly.finance/',
    },
    tko: {
      symbol: 'TKO',
      address: {
        [ChainId.BSC_MAINNET]: '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.tokocrypto.com/',
    },
    itam: {
      symbol: 'ITAM',
      address: {
        [ChainId.BSC_MAINNET]: '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://itam.network/',
    },
    arpa: {
      symbol: 'ARPA',
      address: {
        [ChainId.BSC_MAINNET]: '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://arpachain.io/',
    },
    eps: {
      symbol: 'EPS',
      address: {
        [ChainId.BSC_MAINNET]: '0xA7f552078dcC247C2684336020c03648500C6d9F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://ellipsis.finance/',
    },
    jgn: {
      symbol: 'JGN',
      address: {
        [ChainId.BSC_MAINNET]: '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://jgndefi.com/',
    },
    tlm: {
      symbol: 'TLM',
      address: {
        [ChainId.BSC_MAINNET]: '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 4,
      projectLink: 'https://alienworlds.io/',
    },
    perl: {
      symbol: 'PERL',
      address: {
        [ChainId.BSC_MAINNET]: '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://perlinx.finance/',
    },
    alpa: {
      symbol: 'ALPA',
      address: {
        [ChainId.BSC_MAINNET]: '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://bsc.alpaca.city/',
    },
    hzn: {
      symbol: 'HZN',
      address: {
        [ChainId.BSC_MAINNET]: '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://horizonprotocol.com/',
    },
    suter: {
      symbol: 'SUTER',
      address: {
        [ChainId.BSC_MAINNET]: '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://shield.suterusu.io/',
    },
    cgg: {
      symbol: 'CGG',
      address: {
        [ChainId.BSC_MAINNET]: '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://chainguardians.io/',
    },
    mix: {
      symbol: 'MIX',
      address: {
        [ChainId.BSC_MAINNET]: '0xB67754f5b4C704A24d2db68e661b2875a4dDD197',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mixie.chainguardians.io/',
    },
    hakka: {
      symbol: 'HAKKA',
      address: {
        [ChainId.BSC_MAINNET]: '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://hakka.finance/',
    },
    xed: {
      symbol: 'XED',
      address: {
        [ChainId.BSC_MAINNET]: '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.exeedme.com/',
    },
    τbtc: {
      symbol: 'τBTC',
      address: {
        [ChainId.BSC_MAINNET]: '0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 9,
      projectLink: 'https://www.btcst.finance/',
    },
    alpaca: {
      symbol: 'ALPACA',
      address: {
        [ChainId.BSC_MAINNET]: '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.alpacafinance.org/',
    },
    dfd: {
      symbol: 'DFD',
      address: {
        [ChainId.BSC_MAINNET]: '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://dusd.finance/',
    },
    lmt: {
      symbol: 'LMT',
      address: {
        [ChainId.BSC_MAINNET]: '0x9617857E191354dbEA0b714d78Bc59e57C411087',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://lympo.io/lmt/',
    },
    btt: {
      symbol: 'BTT',
      address: {
        [ChainId.BSC_MAINNET]: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.bittorrent.com/',
    },
    trx: {
      symbol: 'TRX',
      address: {
        [ChainId.BSC_MAINNET]: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://tron.network/',
    },
    win: {
      symbol: 'WIN',
      address: {
        [ChainId.BSC_MAINNET]: '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://winklink.org/',
    },
    mcoin: {
      symbol: 'mCOIN',
      address: {
        [ChainId.BSC_MAINNET]: '0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mirror.finance/',
    },
    math: {
      symbol: 'MATH',
      address: {
        [ChainId.BSC_MAINNET]: '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://mathwallet.org/',
    },
    kun: {
      symbol: 'KUN',
      address: {
        [ChainId.BSC_MAINNET]: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://chemix.io/home',
    },
    qsd: {
      symbol: 'QSD',
      address: {
        [ChainId.BSC_MAINNET]: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://chemix.io/home',
    },
    hyfi: {
      symbol: 'HYFI',
      address: {
        [ChainId.BSC_MAINNET]: '0x9a319b959e33369C5eaA494a770117eE3e585318',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://hyfi.pro/#/',
    },
    oin: {
      symbol: 'OIN',
      address: {
        [ChainId.BSC_MAINNET]: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://oin.finance/',
    },
    doge: {
      symbol: 'DOGE',
      address: {
        [ChainId.BSC_MAINNET]: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://dogecoin.com/',
    },
    fine: {
      symbol: 'FINE',
      address: {
        [ChainId.BSC_MAINNET]: '0x4e6415a5727ea08aAE4580057187923aeC331227',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://refinable.com/',
    },
    one: {
      symbol: 'ONE',
      address: {
        [ChainId.BSC_MAINNET]: '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.bigone.com/',
    },
    pmon: {
      symbol: 'PMON',
      address: {
        [ChainId.BSC_MAINNET]: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://polkamon.com/',
    },
    hotcross: {
      symbol: 'HOTCROSS',
      address: {
        [ChainId.BSC_MAINNET]: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.hotcross.com/',
    },
    τdoge: {
      symbol: 'τDOGE',
      address: {
        [ChainId.BSC_MAINNET]: '0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://www.btcst.finance/',
    },
    btr: {
      symbol: 'BTR',
      address: {
        [ChainId.BSC_MAINNET]: '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.bitrue.com/',
    },
    ubxt: {
      symbol: 'UBXT',
      address: {
        [ChainId.BSC_MAINNET]: '0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://upbots.com/',
    },
    wmass: {
      symbol: 'WMASS',
      address: {
        [ChainId.BSC_MAINNET]: '0x7e396BfC8a2f84748701167c2d622F041A1D7a17',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://massnet.org/en/',
    },
    rfox: {
      symbol: 'RFOX',
      address: {
        [ChainId.BSC_MAINNET]: '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.redfoxlabs.io/',
    },
    xend: {
      symbol: 'XEND',
      address: {
        [ChainId.BSC_MAINNET]: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://xend.finance/',
    },
    cyc: {
      symbol: 'CYC',
      address: {
        [ChainId.BSC_MAINNET]: '0x810EE35443639348aDbbC467b33310d2AB43c168',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://cyclone.xyz/',
    },
    chr: {
      symbol: 'CHR',
      address: {
        [ChainId.BSC_MAINNET]: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 6,
      projectLink: 'https://chromia.com/',
    },
    kalm: {
      symbol: 'KALM',
      address: {
        [ChainId.BSC_MAINNET]: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://kalmar.io/',
    },
    deri: {
      symbol: 'DERI',
      address: {
        [ChainId.BSC_MAINNET]: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://deri.finance/#/index',
    },
    well: {
      symbol: 'WELL',
      address: {
        [ChainId.BSC_MAINNET]: '0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.bitwellex.com/',
    },
    wex: {
      symbol: 'WEX',
      address: {
        [ChainId.BSC_MAINNET]: '0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://wault.finance/',
    },
    waultx: {
      symbol: 'WAULTx',
      address: {
        [ChainId.BSC_MAINNET]: '0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://wault.finance/',
    },
    popen: {
      symbol: 'pOPEN',
      address: {
        [ChainId.BSC_MAINNET]: '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://opendao.io/',
    },
    ez: {
      symbol: 'EZ',
      address: {
        [ChainId.BSC_MAINNET]: '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://easyfi.network/',
    },
    vrt: {
      symbol: 'VRT',
      address: {
        [ChainId.BSC_MAINNET]: '0x5F84ce30DC3cF7909101C69086c50De191895883',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://venus.io/',
    },
    tusd: {
      symbol: 'TUSD',
      address: {
        [ChainId.BSC_MAINNET]: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.trueusd.com/',
    },
    mtrg: {
      symbol: 'MTRG',
      address: {
        [ChainId.BSC_MAINNET]: '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.meter.io/',
    },
    ktn: {
      symbol: 'KTN',
      address: {
        [ChainId.BSC_MAINNET]: '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://kattana.io/',
    },
    qkc: {
      symbol: 'QKC',
      address: {
        [ChainId.BSC_MAINNET]: '0xA1434F1FC3F437fa33F7a781E041961C0205B5Da',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://quarkchain.io/',
    },
    bcfx: {
      symbol: 'bCFX',
      address: {
        [ChainId.BSC_MAINNET]: '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.confluxnetwork.org/',
    },
    mx: {
      symbol: 'MX',
      address: {
        [ChainId.BSC_MAINNET]: '0x9F882567A62a5560d147d64871776EeA72Df41D3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.mxc.com/',
    },
    ata: {
      symbol: 'ATA',
      address: {
        [ChainId.BSC_MAINNET]: '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.ata.network/',
    },
    mbox: {
      symbol: 'MBOX',
      address: {
        [ChainId.BSC_MAINNET]: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.mobox.io/#/',
    },
    boring: {
      symbol: 'BORING',
      address: {
        [ChainId.BSC_MAINNET]: '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.boringdao.com/',
    },
    marsh: {
      symbol: 'MARSH',
      address: {
        [ChainId.BSC_MAINNET]: '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://unmarshal.io/',
    },
    ampl: {
      symbol: 'AMPL',
      address: {
        [ChainId.BSC_MAINNET]: '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 9,
      projectLink: 'https://www.ampleforth.org/',
    },
    o3: {
      symbol: 'O3',
      address: {
        [ChainId.BSC_MAINNET]: '0xEe9801669C6138E84bD50dEB500827b776777d28',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://o3swap.com/',
    },
    hai: {
      symbol: 'HAI',
      address: {
        [ChainId.BSC_MAINNET]: '0xaA9E582e5751d703F85912903bacADdFed26484C',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 8,
      projectLink: 'https://hacken.io/',
    },
    htb: {
      symbol: 'HTB',
      address: {
        [ChainId.BSC_MAINNET]: '0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.hotbit.io/',
    },
    woo: {
      symbol: 'WOO',
      address: {
        [ChainId.BSC_MAINNET]: '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://woo.network/',
    },
    $dg: {
      symbol: '$DG',
      address: {
        [ChainId.BSC_MAINNET]: '0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://decentral.games/',
    },
    safemoon: {
      symbol: 'SAFEMOON',
      address: {
        [ChainId.BSC_MAINNET]: '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 9,
      projectLink: 'https://safemoon.net/',
    },
    axs: {
      symbol: 'AXS',
      address: {
        [ChainId.BSC_MAINNET]: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://axieinfinity.com/',
    },
    c98: {
      symbol: 'c98',
      address: {
        [ChainId.BSC_MAINNET]: '0xaec945e04baf28b135fa7c640f624f8d90f1c3a6',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://coin98.com/',
    },
    pots: {
      symbol: 'POTS',
      address: {
        [ChainId.BSC_MAINNET]: '0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://moonpot.com/',
    },
    near: {
      symbol: 'NEAR',
      address: {
        [ChainId.BSC_MAINNET]: '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://near.org/',
    },
    pols: {
      symbol: 'POLS',
      address: {
        [ChainId.BSC_MAINNET]: '0x7e624FA0E1c4AbFD309cC15719b7E2580887f570',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.polkastarter.com/',
    },
    pha: {
      symbol: 'PHA',
      address: {
        [ChainId.BSC_MAINNET]: '0x0112e557d400474717056C4e6D40eDD846F38351',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://phala.network/',
    },
    kkac: {
      symbol: 'KKAC',
      address: {
        [ChainId.BSC_MAINNET]: '0x65aDc52BfD0E3d9Df80Be6E36F330E757862e2Bd',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.kaco.finance/',
    },
    kcake: {
      symbol: 'KCAKE',
      address: {
        [ChainId.BSC_MAINNET]: '0xa70c4580F1e00C1d7A9D0280832c0D513a6D530F',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.kaco.finance/',
    },
    cake: {
      symbol: 'CAKE',
      address: {
        [ChainId.BSC_MAINNET]: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://pancakeswap.finance/',
    },
    kalpaca: {
      symbol: 'KALPACA',
      address: {
        [ChainId.BSC_MAINNET]: '0xBd6D17123Ec731adFf1cE2F9f7Af1aBC26E5EBfd',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.kaco.finance/',
    },
    kalpie: {
      symbol: 'KALPIE',
      address: {
        [ChainId.BSC_MAINNET]: '0xc799B336f15A42AD506b0a792543907245c81110',
        [ChainId.BSC_TESTNET]: '',
      },
      decimals: 18,
      projectLink: 'https://www.kaco.finance/',
    },
  },
};

export const JPYC =
  chainKey === CHAINKEY.SDN
    ? new Token(
        chainId,
        tokens[chainKey].jpyc.address[chainId],
        tokens[chainKey].jpyc.decimals,
        tokens[chainKey].jpyc.symbol,
        'JPYC Coin',
      )
    : null;

export const main_tokens = {
  astr: {
    symbol: 'ASTR',
    name: 'ASTR Token',
    decimals: 18,
    address: {
      [ChainId.ASTR_MAINNET]: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
      [ChainId.ASTR_TESTNET]: '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
    },
    projectLink: 'https://blockscout.com/astar/',
  },
  sdn: {
    symbol: 'WSDN',
    name: 'Wrapped SDN',
    decimals: 18,
    address: {
      [ChainId.SDN_MAINNET]: '0x0f933Dc137D21cA519ae4C7E93f87a4C8EF365Ef',
      [ChainId.SDN_TESTNET]: '0x0f933Dc137D21cA519ae4C7E93f87a4C8EF365Ef',
    },
    projectLink: 'https://blockscout.com/shiden/',
  },
  wbnb: {
    symbol: 'BNB',
    name: 'BNB Token',
    address: {
      [ChainId.BSC_MAINNET]: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      [ChainId.BSC_TESTNET]: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://www.binance.com/',
  },
  kaco: {
    name: 'Adao Token',
    symbol: 'KAC',
    address: {
      [ChainId.ASTR_MAINNET]: '0xb12c13e66ade1f72f71834f2fc5082db8c091358',
      [ChainId.ASTR_TESTNET]: '0xb12c13e66ade1f72f71834f2fc5082db8c091358',
      [ChainId.SDN_MAINNET]: '0xb12c13e66ade1f72f71834f2fc5082db8c091358',
      [ChainId.SDN_TESTNET]: '0xb12c13e66ade1f72f71834f2fc5082db8c091358',
      [ChainId.BSC_MAINNET]: '0xf96429A7aE52dA7d07E60BE95A3ece8B042016fB',
      [ChainId.BSC_TESTNET]: '0x0bA819e30016Cf682C7795b44859148C65e62292',
    },
    decimals: 18,
    projectLink: BASE_BSC_SCAN_URL,
  },
};
export const SDN =
  chainKey === CHAINKEY.SDN ? new Token(chainId, main_tokens.sdn.address[chainId], 18, 'WSDN', 'Wrapped SDN') : null;

export const DEFAULT_Token = {
  [ChainId.BSC_MAINNET]: {
    address: main_tokens.wbnb.address[ChainId.BSC_MAINNET],
    decimals: main_tokens.wbnb.decimals,
    symbol: main_tokens.wbnb.symbol,
    name: main_tokens.wbnb.name,
  },
  [ChainId.BSC_TESTNET]: {
    address: main_tokens.wbnb.address[ChainId.BSC_TESTNET],
    decimals: main_tokens.wbnb.decimals,
    symbol: main_tokens.wbnb.symbol,
    name: main_tokens.wbnb.name,
  },
  [ChainId.ASTR_MAINNET]: {
    address: main_tokens.astr.address[ChainId.ASTR_MAINNET],
    decimals: main_tokens.astr.decimals,
    symbol: main_tokens.astr.symbol,
    name: main_tokens.astr.name,
  },
  [ChainId.ASTR_TESTNET]: {
    address: main_tokens.astr.address[ChainId.ASTR_TESTNET],
    decimals: main_tokens.astr.decimals,
    symbol: 'SBY',
    name: 'SBY Token',
  },
  [ChainId.SDN_MAINNET]: {
    address: main_tokens.sdn.address[ChainId.SDN_MAINNET],
    decimals: main_tokens.sdn.decimals,
    symbol: main_tokens.sdn.symbol,
    name: main_tokens.sdn.name,
  },
};
export const Base_Token: Token = new Token(
  chainId,
  DEFAULT_Token[chainId].address,
  DEFAULT_Token[chainId].decimals,
  DEFAULT_Token[chainId].symbol,
  DEFAULT_Token[chainId].name,
);
export const Adao: Token = new Token(
  chainId,
  main_tokens.kaco.address[chainId],
  18,
  main_tokens.kaco.symbol,
  main_tokens.kaco.name,
);

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as any,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as any,
    '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
};
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.SDN_MAINNET]: new Token(
    ChainId.SDN_MAINNET as any,
    '0x65e66a61d0a8f1e686c2d6083ad611a10d84d97a',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.SDN_TESTNET]: new Token(
    ChainId.SDN_TESTNET as any,
    '0xb12c13e66ade1f72f71834f2fc5082db8c091358',
    18,
    'BUSD',
    'Binance USD',
  ),

  [ChainId.ASTR_MAINNET]: new Token(
    chainId,
    tokens[chainKey].usdc.address[chainId],
    tokens[chainKey].usdc.decimals,
    tokens[chainKey].usdc.symbol,
    'USD Coin',
  ),
  [ChainId.ASTR_TESTNET]: new Token(
    chainId,
    tokens[chainKey].usdc.address[chainId],
    tokens[chainKey].usdc.decimals,
    tokens[chainKey].usdc.symbol,
    'USD Coin',
  ),
};
export const DOT: { [chainId: number]: Token } = {
  [ChainId.ASTR_MAINNET]: new Token(
    ChainId.ASTR_MAINNET as any,
    '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    18,
    'DOT',
    'DOT Token',
  ),
  [ChainId.ASTR_TESTNET]: new Token(
    ChainId.ASTR_TESTNET as any,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'DOT',
    'FAKE DOT',
  ),

  [ChainId.SDN_MAINNET]: new Token(
    ChainId.SDN_MAINNET as any,
    '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    18,
    'DOT',
    'DOT Token',
  ),
  [ChainId.SDN_TESTNET]: new Token(
    ChainId.SDN_TESTNET as any,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'DOT',
    'FAKE DOT',
  ),

  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as any,
    '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    18,
    'DOT',
    'DOT Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as any,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'DOT',
    'FAKE DOT',
  ),
};
export const ALPACA: { [chainId: number]: Token } = {
  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as any,
    '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
    18,
    'ALPACA',
    'Alpaca',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as any,
    '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
    18,
    'ALPACA',
    'Alpaca',
  ),
};
export const KSM: { [chainId: number]: Token } = {
  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as any,
    '0x2aa69e8d25c045b659787bc1f03ce47a388db6e8',
    18,
    'KSM',
    'KSM Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as any,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'KSM',
    'FAKE KSM',
  ),
};

export const DAI = new Token(
  ChainId.BSC_MAINNET as any,
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  18,
  'DAI',
  'Dai Stablecoin',
);
export const USDT = new Token(
  chainId,
  tokens[chainKey].usdt.address[chainId],
  tokens[chainKey].usdt.decimals,
  tokens[chainKey].usdt.symbol,
  'Tether USD',
);
export const BTCB: { [chainId: number]: Token } = {
  [ChainId.BSC_MAINNET]: new Token(
    ChainId.BSC_MAINNET as any,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'BTCB',
    'Binance BTC',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as any,
    '0x6ce8da28e2f864420840cf74474eff5fd80e65b8',
    18,
    'BTCB',
    'Binance BTC',
  ),
};

export const UST: { [chainId: number]: Token } = {
  [ChainId.SDN_MAINNET]: new Token(
    ChainId.SDN_MAINNET as any,
    '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    18,
    'UST',
    'Wrapped UST Token',
  ),
};

export const ETH = {
  [ChainId.SDN_MAINNET]: new Token(
    ChainId.SDN_MAINNET as any,
    '0x765277eebeca2e31912c9946eae1021199b39c61',
    18,
    'ETH',
    'Binance-Peg Ethereum Token',
  ),
  [ChainId.ASTR_MAINNET]: new Token(
    ChainId.ASTR_MAINNET as any,
    '0x765277eebeca2e31912c9946eae1021199b39c61',
    18,
    'ETH',
    'Binance-Peg Ethereum Token',
  ),
};
export const ibASTR: { [chainId: number]: Token } = {
  [ChainId.ASTR_TESTNET]: new Token(
    ChainId.ASTR_TESTNET as any,
    '0x50CE77Ed745374980aE8366424e79D08bD1BB37B',
    18,
    'ibSBY',
    'ibSBY Coin',
  ),
  [ChainId.ASTR_MAINNET]: new Token(
    ChainId.ASTR_MAINNET as any,
    '0x3BFcAE71e7d5ebC1e18313CeCEbCaD8239aA386c',
    18,
    'ib' + main_tokens.astr.symbol,
    'ib' + main_tokens.astr.name,
  ),
};
export const USDC = new Token(
  chainId,
  tokens[chainKey].usdc.address[chainId],
  tokens[chainKey].usdc.decimals,
  tokens[chainKey].usdc.symbol,
  'USD Coin',
);

export default tokens;
